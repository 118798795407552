import { createRoot } from "react-dom/client";
import React from "react";
import { MainNavigation } from "~assets/frontend/frame/frm-navigation/MainNavigation";
import { MainNavigationStructValidator } from "~assets/types/frontend/frame/MainNavigationStructValidator";
import { BurgerNavigation } from "~assets/frontend/frame/frm-navigation/BurgerNavigation";
export const frameNavigationLoader = () => {
    var _a, _b;
    const mainNavigationElement = document.querySelector('[data-inject="header-navigation"]');
    const burgerNavigationElement = document.querySelector('[data-inject="burger-navigation"]');
    if (mainNavigationElement || burgerNavigationElement) {
        const json = (_a = burgerNavigationElement === null || burgerNavigationElement === void 0 ? void 0 : burgerNavigationElement.getAttribute("data-navigation")) !== null && _a !== void 0 ? _a : mainNavigationElement === null || mainNavigationElement === void 0 ? void 0 : mainNavigationElement.getAttribute("data-navigation");
        const jsonLanguages = (_b = burgerNavigationElement === null || burgerNavigationElement === void 0 ? void 0 : burgerNavigationElement.getAttribute("data-languages")) !== null && _b !== void 0 ? _b : mainNavigationElement === null || mainNavigationElement === void 0 ? void 0 : mainNavigationElement.getAttribute("data-languages");
        if (typeof json === "string") {
            const data = JSON.parse(json);
            if (MainNavigationStructValidator(data)) {
                const struct = data;
                if (mainNavigationElement) {
                    const mainRoot = createRoot(mainNavigationElement);
                    mainRoot.render(React.createElement(MainNavigation, { items: struct.items }));
                }
                if (burgerNavigationElement) {
                    const languages = (jsonLanguages === null || jsonLanguages === void 0 ? void 0 : jsonLanguages.length) ? JSON.parse(jsonLanguages) : undefined;
                    const burgerRoot = createRoot(burgerNavigationElement);
                    burgerRoot.render(React.createElement(BurgerNavigation, { items: struct.items, languages: languages === null || languages === void 0 ? void 0 : languages.languages }));
                }
            }
            else {
                console.error("MainNavigationStructValidator failed", MainNavigationStructValidator.errors);
            }
        }
    }
};
