/* @formatter:off */
///// Generated class by `yarn run json-validators`         /////
///// Do not modify this file manually                      /////
///// Regenerate by changing `MainNavigationStruct.ts` file /////
///// and running `yarn run json-validators`                /////
import Ajv from "ajv";
const ajv = new Ajv();
export const MainNavigationStructValidator = ajv.compile({
    "$schema": "http://json-schema.org/draft-07/schema#",
    "$ref": "#/definitions/MainNavigationStruct",
    "definitions": {
        "MainNavigationStruct": {
            "type": "object",
            "properties": {
                "items": {
                    "type": "array",
                    "items": {
                        "$ref": "#/definitions/NavigationItemStruct"
                    }
                }
            },
            "required": [
                "items"
            ],
            "additionalProperties": false
        },
        "NavigationItemStruct": {
            "type": "object",
            "properties": {
                "contentId": {
                    "type": "number"
                },
                "locationId": {
                    "type": "number"
                },
                "title": {
                    "type": "string"
                },
                "priority": {
                    "type": "number"
                },
                "href": {
                    "type": [
                        "string",
                        "null"
                    ]
                },
                "display": {
                    "type": "string",
                    "enum": [
                        "normal",
                        "inline",
                        "preview",
                        "external"
                    ]
                },
                "items": {
                    "anyOf": [
                        {
                            "type": "array",
                            "items": {
                                "$ref": "#/definitions/NavigationItemStruct"
                            }
                        },
                        {
                            "type": "null"
                        }
                    ]
                }
            },
            "required": [
                "contentId",
                "locationId",
                "title",
                "priority",
                "href",
                "display",
                "items"
            ],
            "additionalProperties": false
        }
    }
});
