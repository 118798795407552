// Stolen from https://stackoverflow.com/a/72567557/633258
// License: "Might be for someone my solution will be also useful"
import { useEffect, useState } from "react";
import { IBEXA_RESPONSIVE_IMAGE_ENDPOINT } from "~assets/frontend/_util/api-endpoints";
export const useImageStruct = (imageStruct, sizes) => {
    if (imageStruct === null) {
        return;
    }
    const [imageResponse, setImageResponse] = useState(null);
    let imageId = null;
    if (imageStruct.fieldValue && "id" in imageStruct.fieldValue) {
        imageId = imageStruct.fieldValue.id;
    }
    else if (imageStruct.fieldValue && "imageId" in imageStruct.fieldValue) {
        imageId = imageStruct.fieldValue.imageId;
    }
    if (!imageId) {
        // Fallback to stringified object
        imageId = JSON.stringify(imageStruct);
    }
    const cacheKey = imageId + JSON.stringify(Object.values(sizes));
    useEffect(() => {
        if (!cacheKey) {
            return;
        }
        const cachedItem = sessionStorage.getItem(cacheKey);
        if (typeof cachedItem === "string" && cachedItem !== "undefined") {
            setImageResponse(JSON.parse(cachedItem));
            return;
        }
        const bodyObject = {
            fieldValue: imageStruct.fieldValue,
            variationName: "scale_crop_webp",
            sizes: sizes,
        };
        const headers = new Headers();
        headers.append("Accept", "application/json");
        headers.append("X-SITEACCESS", location.pathname.split("/")[1]);
        const options = {
            method: "GET",
            headers: headers,
        };
        const params = new URLSearchParams();
        params.append("body", JSON.stringify(bodyObject));
        fetch(IBEXA_RESPONSIVE_IMAGE_ENDPOINT + "?" + params.toString(), options)
            .then((response) => response.json())
            .then((data) => {
            sessionStorage.setItem(cacheKey, JSON.stringify(data.ResponsiveImage));
            setImageResponse(data.ResponsiveImage);
        });
        return () => {
            setImageResponse(null);
        };
    }, [cacheKey]); // Cache key implicitly depends on imageStruct and sizes. If we them as dependencies directly, we get an infinite update loop as imageStruct changes every render somewhere.
    return imageResponse;
};
